import { graphql } from 'gatsby'
import React from 'react'
import Seo from '../components/Seo'
import Image1 from '../images/dog-illustration-walking-service.inline.svg'
import Image2 from '../images/dog-illustration-wellness-dark.inline.svg'
import Wellness from '../images/wellness.inline.svg'

export default function Services({data}) {
  return (
    <div className='services'>
      <Seo 
        description={'Bespoke solo dog walking service covering Chapel Allerton and surrounding areas of North Leeds. These walks are ideal for reactive dogs, older dogs and training dogs recall or loose lead skills'}
        image={'/og.jpg'}
        title={'TED: The Enriched Dog | Dog Walking Leeds'}
      />
      <div className="intro">
        <h1>Services</h1>

        <article className='block'>
          <h2>Bespoke Dog Walking Service</h2>
          <div className="text">
            <p>Bespoke solo dog walks are designed to meet your dog's individual needs. During walks, your dog will be provided with both physical and mental stimulation from start to finish.</p>
            <p>As a certified DTC dog trainer, I can support any ongoing training plans already in place during walks and also help your dog learn new skills. </p>
          </div>
          <Image1 />
          <div className="text clamp-width">
            <p><strong>These walks are ideal for any dog including:</strong></p>
            <ul>
              <li>Reactive Dogs</li>
              <li>Older dogs who require a little more TLC</li>
              <li>Dogs working on their recall or loose lead skills</li>
              <li>Dogs who are nervous or lack confidence</li>
              <li>Dogs with underlying medical conditions</li>
              <li>Dogs who prefer not to walk as part of a group</li>
              <li>Dogs recovering from an operation</li>
              <li>Dogs unable to travel in a van</li>
            </ul>
            <div className="button inverted no-hover">Walks are for 30 mins and cost £13.50</div>
          </div>
          <div className="text heading">
            <p>Based in Chapel Allerton and covering surrounding areas, such as Roundhay, Meanwood, Oakwood, Shadwell, Potternewton. Please note, this list is not exhaustive therefore please do not hesitate to contact if you live in North Leeds and your area is not listed.</p>
          </div>
        </article>
        
        <article className='block'>
          <div id="wellness"></div>
          <h2>Wellness toolkits for Ted</h2>
          <div className="text">
            <p>Through studying with the Dog Training College and working on a one to one basis with many dogs, I have learnt that a lot of behavioural issues stem from our dogs having poor mental health.</p>
          </div>
          <Image2 />
          <div className="text">
            <p>Wellness toolkits focus on enriching your dog's lifestyle and ensuring their <strong>emotional needs</strong>, both mentally and physically are being met. The toolkit will take into account breed specifics and provide you with <strong>bespoke guidance</strong>, advice and games to improve and understand stress management, physical exercise, instinctive behaviours and predatory outlets, reinforcement history and <strong>mental enrichment</strong>.</p>

            <p>Your dog's medical history and overall health will also be factored in. </p>  
          </div>
          <div className="text clamp-width">
              <div className="button inverted no-hover">Wellness toolkits cost £50 and include:</div>
            
              <ul>
                <li>1 hour session, either in person or via zoom depending on location, to discuss your dogs current lifestyle, routine and any struggles you may be experiencing with your dog.</li>
                <li>Bespoke wellness toolkit to provide you with guidance, advice and games to enrich your dog's lifestyle and set them up for success.</li>
              </ul>
              <Wellness className="larger" />
          </div>
        </article>
      </div>
    </div>
  )
}